form {
	label, .form-label {
		font-family: $alt-font;
		font-weight: $alt-bold;
	}
	input[type="text"],
	input[type="email"],
	select,
	textarea {
		height: 30px;
		font-family: $main-font;
		font-weight: $main-normal;
		border-color: #acac9c;
		margin-top: 6px;
	}
	.subtext {
		font-size: rem-calc(12);
		font-style: italic;
		display: block;
		margin-top: -15px;
	}
	.field-full {
		grid-column: 1 / -1;
	}
	.radio-group {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 20px;
	}
}

.form-wrapper {
	max-width: 635px;
	form {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		grid-column-gap: 30px;
	}
}


.form-container {
	background: $brand;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0,0,0,.05);
	border-radius: 6px;
	@include breakpoint(large) {
		padding: 30px;
	}
	.heading {
		font-size: rem-calc(28);
		color: $white;
		text-align: center;
		line-height: 1.14;
		margin-bottom: 20px;
	}
	label {
		font-family: $main-font;
		font-size: rem-calc(14);
		color: $white;
		line-height: 1.28;
		.text {
		    display: block;
		    margin-bottom: 2px;
		}
	}
	label.checkbox-wrapper {
        font-weight: $main-normal;
        margin-bottom: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        input {
            margin-bottom: 0;
        }
    }
	form {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 20px;
		@include breakpoint(400px) {
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		}
	}
	input[type="email"],
	input[type="text"] {
	    height: 43px;
	    border-radius: 0;
	    margin-top: 0;
	    border-color: $brand;
	}
	a {
		color: $white;
	}
	.field-full {
		grid-column: 1 / -1;
	}
	input[type="checkbox"] {
		margin: 0 8px 5px 0;
	}
}

.newsletter-subscribe-form {
	max-width: 500px;
	background: $white;
	padding: 20px 10px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0,0,0,.05);
	.button {
		margin-top: 12px;
		margin-bottom: 0;
	}
	input[type="email"] {
		margin-bottom: 10px;
	}
	input[type="checkbox"] {
		margin: 3px 5px 0 0;
	}
	.check-options .option {
		font-size: rem-calc(14);
		line-height: 1.2;
		display: flex;
		align-items: flex-start;
	}
}
