.soil-calculator {
    background: $white;
    margin-bottom: 50px;
    border-radius: 2px;
    overflow: hidden;
    .dimension-fields {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
    }
    .dimension-field {
        h4 {
            font-family: $main-font;
            font-weight: $main-bold;
            margin-bottom: 2px;
        }
    }
    .dimension-input-fields {
        max-width: 200px;
        // height: 50px;
        background: #FBFAF8;
        padding: 10px;
        border-radius: 2px;
        border: 1px solid #BABABA;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        input {
            background: $white;
            width: 40px;
            height: 40px;
            font-size: rem-calc(18);
            font-weight: $main-bold;
            text-align: center;
            border: 1px solid $main-color;
            border-radius: 2px;
            padding: 0;
            margin: 0;
            box-shadow: none;
            -webkit-touch-callout: none;
        }
        &.disabled {
            background-color: #EBEBE4;
            input:disabled,
            select:disabled {
                background-color: transparent;
            }
        }
    }
    .dimension-input-field {
        display: inline-flex;
        align-items: center;
        gap: 6px;
    }
}

.soil-calculator-heading {
    background: $brand;
    padding: 20px 20px;
    @include breakpoint(medium) {
        padding: 20px 30px;
    }
    h2 {
        color: $white;
        margin: 0;
    }
}

.soil-calculator-header-image {
    img {
        box-shadow: none;
        border: 0;
        border-radius: 0;
        margin: 0;
    }
}

.soil-calculator-content {
    padding: 20px 15px 40px;
    @include breakpoint(medium) {
        padding: 20px 30px 40px;
    }
    .input-section {
        margin-bottom: 30px;
    }
    .section-heading {
        margin-bottom: 15px;
        &.short {
            margin-bottom: 9px;
        }
        h3 {
            font-family: $main-font;
            font-weight: $main-bold;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
        .number {
            width: 30px;
            height: 30px;
            background: $light-tan;
            line-height: 1;
            border-radius: 4px;
            border: 1px solid $tan;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
    .input-button {
        height: 48px;
        background-color: $white;
        font-size: rem-calc(16);
        font-weight: $main-bold;
        color: $black;
        text-align: left;
        line-height: 1;
        padding: 5px 24px 5px 12px;
        border-radius: 2px;
        border: solid 3px #C5BDA4;
        transition: background-color .3s, color .3s, border-color .3s;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        &:hover, &:focus-visible, &.selected {
            background-color: $brand;
            color: $white;
            border-color: $brand;
        }
        img {
            border: 0;
            box-shadow: none;
            margin: 0;
        }
    }
    .input-button-group {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .submit-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        flex-wrap: wrap;
        button[type="submit"] {
            font-size: rem-calc(20);
            line-height: 1;
            padding: 13px 27px 13px 24px;
            margin: 0;
            border-radius: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            .icon {
                color: $light-blue;
                transition: color .3s;
            }
            &:hover {
                .icon {
                    color: $white;
                }
            }
            &:focus {
                background-color: $brand;
            }
            &:focus-visible {
                background-color: $brand-alt;
            }
            img {
                border: 0;
                box-shadow: none;
                margin: 0;
            }
        }
        button[type="button"] {
            color: $brand;
            cursor: pointer;
        }
    }
}

.soil-calculator-results {
    margin-top: 40px;
    .soil-needed {
        background-color: $light-tan;
        padding: 20px 20px 40px;
        border: 1px solid #F2EEEA;
        border-radius: 4px;
    }
    .heading {
        font-family: $heading-font;
        font-size: rem-calc(30);
        font-weight: $heading-bold;
        text-align: center;
        line-height: 1;
        margin-bottom: 15px;
    }
    .amount-needed {
        margin-bottom: 6px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 8px;
        select {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%2863, 101, 171%29"></polygon></svg>');
            background-position: right -1rem center;
            max-width: 150px;
            height: auto;
            font-size: rem-calc(24);
            font-weight: $main-bold;
            padding-right: 20px;
            padding-left: 6px;
            padding-top: 0;
            padding-bottom: 0;
            border-radius: 1px;
            border: 1px solid #9F9F9F;
            margin: 0;
            &:focus-visible {
                outline: 3px dashed $brand;
            }
        }
        span {
            font-size: rem-calc(24);
            line-height: 1;
        }
        .amount {
            font-size: rem-calc(50);
            font-weight: $main-bold;
            color: $brand;
            line-height: 1;
        }
    }
    .amount-details {
        font-size: rem-calc(20);
        font-style: italic;
        line-height: 1.25;
        text-align: center;
        margin-bottom: 30px;
        .amount-highlight {
            color: $brand;
            font-weight: $main-bold;
        }
    }
    .bags-needed-wrapper {
        text-align: center;
    }

    .bags-needed {
        background-color: $brand;
        color: $white;
        text-align: center;
        border-radius: 4px;
        padding: 20px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 8px;
        @include breakpoint(medium) {
            // justify-content: flex-start;
            flex-wrap: nowrap;
        }
        > * {
            flex-shrink: 0;
        }
        select {
            background-color: $white;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%2850, 102, 76%29"></polygon></svg>');
            background-position: right -1rem center;
            max-width: 110px;
            height: auto;
            font-size: rem-calc(24);
            font-weight: $main-bold;
            color: $main-color;
            padding-right: 20px;
            padding-left: 6px;
            padding-top: 0;
            padding-bottom: 0;
            border-radius: 1px;
            border: 1px solid $brand;
            margin: 0;
        }
        span {
            font-size: rem-calc(24);
            line-height: 1;
        }
        .amount {
            font-size: rem-calc(50);
            font-weight: $main-bold;
            line-height: 1;
        }
    }
}

.soil-calculator-products {
    text-align: left;
    padding-top: 50px;
    .sub-heading {
        font-size: rem-calc(20);
        color: $brand;
        line-height: 1.25;
        border-bottom: solid 2px #dfdbd8;
        padding-bottom: 6px;
        margin: 0 0 20px;
    }
}

.soil-calculator-products-list {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 30px;
    h3 {
        font-size: rem-calc(18);
        line-height: 1.17;
        margin-bottom: 0;
    }
    img {
        box-shadow: none;
        border: 0;
        border-radius: 0;
        margin: 0 0 10px;
    }
    .item {
        width: 100%;
        max-width: 150px;
        text-align: center;
        margin: 0 auto;
    }
    .size-badge {
        span {
            background: #E3EFFF;
            font-size: 1rem;
            color: $brand;
            line-height: 1;
            padding: 5px 10px;
            border-radius: 2px;
            border: 1px solid $brand;
            margin-bottom: 10px;
            display: inline-block;
        }
        .purple {
            background: #F2E5FF;
            color: #542682;
            border-color: #542682;
        }
        .green {
            background: #EAFFE6;
            color: #368226;
            border-color: #368226;
        }
        .yellow {
            background: #FFF5D1;
            color: #8E7005;
            border-color: #8E7005;
        }
    }
}