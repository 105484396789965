.banner-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 500px;
	position: relative;
    display: flex;
	.inner {
	    height: 100%;
	    width: 100%;
	    display: flex;
	    align-items: flex-end;
	    justify-content: flex-start;
	}
	h1 {
		background: #ffffff;
		color: #44a231;
		font-size: rem-calc(30);
		line-height: 1;
		box-shadow: 0 0 10px rgba(0,0,0,.05);
		padding: 10px 20px;
		border: 0;
		border-radius: 5px 5px 0 0;
		margin-bottom: 0;
		display: inline-block;
		bottom: 0;
		left: 0;
	}
}

@include breakpoint(medium) {
	.banner-image {
		h1 {
			font-size: rem-calc(40);
		}
	}
}