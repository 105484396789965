.page-footer {
	background-color: #e9e4d8;
	padding: 30px 0;
	.inner {
		display: grid;
		grid-gap: 25px 30px;
	}
	h2 {
		font-size: rem-calc(20);
		color: $main-color;
		margin-bottom: 10px;
		display: flex;
		img {
			margin: 0 8px 0 0;
		}
		.icon {
			color: $brand;
			margin: 0 8px 0 0;
			display: flex;
		}
		.icon-calendar {
			margin-top: 3px;
		}
	}
	p {
		font-size: rem-calc(16);
		line-height: 1.5;
		margin-bottom: 12px;
	}
	a {
		text-decoration: none;
	}
	.button {
		margin-bottom: 0;
	}
	.wide-col {
		background: $white;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0,0,0,.05);
		.media img {
			margin: 0;
		}
		.text {
			margin-bottom: 20px;
		}
	}
}

@include breakpoint(medium) {
	.page-footer {
		.inner {
			grid-template-areas: "footer-col-1 footer-col-2"
			                     "footer-wide-col footer-wide-col";
		}
		.col-1 {
			grid-area: footer-col-1;
		}
		.col-2 {
			grid-area: footer-col-2;
		}
		.wide-col {
			grid-area: footer-wide-col;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.text {
				padding-right: 30px;
				margin-bottom: 0;
			}
		}
	}
}

@include breakpoint(large) {
	.page-footer {
		.inner {
			grid-template-areas: "footer-col-1 footer-wide-col"
			"footer-col-2 footer-wide-col";
		}
		.wide-col {
			padding: 30px;
			flex-wrap: wrap;
			justify-content: center;
			align-self: start;
			img {
				max-width: 235px;
			}
			.text {
				margin-bottom: 20px;
			}
			.media {
				flex-shrink: 0;
			}
		}
	}
}

@include breakpoint(xlarge) {
	.page-footer {
		.wide-col {
			flex-wrap: nowrap;
			justify-content: space-between;
			.text {
				margin-bottom: 0;
			}
		}
	}
}

// Pre page footer
.pre-footer {
	background-color: $brand;
	color: $white;
	padding: 60px 0 0;
	.inner > * {
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
	h2 {
		font-size: rem-calc(20);
		line-height: 1.25;
		color: $white;
		margin-bottom: 5px;
		.icon {
			color: $light-blue;
			margin-right: 8px;
			display: inline-block;
		}
	}
	p {
		font-size: rem-calc(16);
		line-height: 1.25;
		margin-bottom: 10px;
	}
	a {
		font-size: rem-calc(18);
		color: #d3e6fd;
		text-decoration: none;
		&:hover, &:focus {
			color: $white;
		}
	}
	.accent-image {
		img {
			margin-bottom: 0;
		}
	}
	a.arrow-link::after {
		content: url('/images/cssimg/arrow_right_light_blue.svg');
	}
}
.pre-footer-item {
	margin-bottom: 32px;
}

@include breakpoint(950px) {
	.pre-footer {
		padding: 30px 0 0;
		.inner {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
			grid-gap: 30px;
			> * {
				max-width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
		.accent-image {
			order: 0;
			position: relative;
			img {
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
	.pre-footer-item {
		margin-bottom: 52px;
		order: 1;
	}
}