.page-header .main-nav {
	display: none;
}

body.offscreen-nav-visible {
	position: fixed;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: $black;
		opacity: .75;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.offscreen-nav {
	width: 100%;
	max-width: 375px;
	height: 100%;
	background-color: $white;
	padding: 18px 0;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 250;
	transform: translateX(100%);
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	visibility: hidden;
	overflow: scroll;
	&.visible {
		z-index: 2100;
		visibility: visible;
		transform: translateX(0);
		transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	}
	.active > .nav-item, .active > a {
		color: $brand-accent;
	}
	.header {
		margin-bottom: 45px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo {
			max-width: 200px;
		}
		img {
			margin: 0;
		}
	}
	li {
		color: $brand;
		line-height: 1;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
	nav > ul > li {
		font-size: rem-calc(22);
		font-weight: $main-bold;
		border-bottom: solid 1px $light-tan;
		padding-top: 3px;
		padding-bottom: 12px;
		padding-right: 30px;
		margin-bottom: 10px;
		&:last-child {
			border: 0;
		}
		> ul {
			margin-top: 15px;
			margin-bottom: 8px;
		}
		> ul > li {
			font-size: 18px;
			font-weight: $main-normal;
			margin-bottom: 15px;
		}
	}
	.has-dropdown {
		padding-right: 0;
		position: relative;
		> span {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 10px;
			justify-content: space-between;
		}
		.nav-caret {
			width: 20px;
			height: 20px;
			cursor: pointer;;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			transition: transform .3s;
		}
		&.active > span .nav-item {
			color: $brand-accent;
		}
		.nav-caret.active {
			transform: rotate(180deg);
		}
	}
	.dropdown {
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 0;
		// display: none;
		> li:first-child {
			padding-top: 15px;
		}
		li {
			padding-left: 0;
		}
		li > a {
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: flex-start;
		}
	}
	.tag {
		background: $brand-alt;
		font-size: rem-calc(10);
		font-weight: $main-bold;
		color: $white;
		line-height: 1;
		padding: 5px 5px 3px;
		border-radius: 2px;
	}
	a {
		text-decoration: none;
	}
}

@include breakpoint(large) {
	.page-header {
		.nav-toggle {
			display: none;
		}
		.main-nav {
			color: $brand;
			align-self: flex-end;
			display: block;
			li {
				line-height: 1.2;
			}
			nav > ul {
				display: flex;
				justify-content: flex-end;
			}
			nav > ul > li {
				font-weight: $main-bold;
				margin-left: 22px;
				padding-bottom: 10px;
			}
			.active > .nav-item, .active > a {
				color: $brand-accent;
			}
			.has-dropdown {
				padding-right: 15px;
				cursor: pointer;
				position: relative;
				z-index: 500;
				&::after {
					content: '';
					background: url('/images/cssimg/down_triangle.svg') no-repeat left top;
					background-size: cover;
					width: 9px;
					height: 6px;
					display: block;
					position: absolute;
					right: 0;
					top: 7px;
					transition: transform .3s;
				}
				&.open {
					color: $brand-accent;
				}
				&:hover, &:focus, &:focus-within {
					color: $brand-accent;
					&::after {
						transform: rotate(180deg);
					}
					.dropdown {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			.dropdown {
				width: 240px;
				background-color: $white;
				border: 1px solid #ede8e5;
				border-radius: 3px;
				padding: 15px;
				position: absolute;
				top: 100%;
				left: 0;
				visibility: hidden;
				opacity: 0;
				transition: opacity .3s, visibility .3s;
				li {
					font-size: rem-calc(16);
					font-weight: $main-normal;
					padding-left: 0;
					margin-bottom: 10px;
					a {
						display: flex;
						align-items: center;
						gap: 8px;
						justify-content: flex-start;
					}
				}
			}
			nav > ul > li:last-child .dropdown {
				left: auto;
				right: 0;
			}
			a {
				text-decoration: none;
				&:hover, &:focus {
					color: $brand-accent;
				}
			}
			.tag {
				background: $brand-alt;
				font-size: rem-calc(10);
				font-weight: $main-bold;
				color: $white;
				line-height: 1;
				padding: 5px 5px 3px;
				border-radius: 2px;
			}
		}
	}
	.offscreen-nav {
		display: none;
	}
}
