.page-sub-footer {
	font-size: rem-calc(14);
	line-height: 1.29;
	padding: 15px 0 25px;
	flex-wrap: wrap;
	a {
		color: $brand;
		text-decoration-thickness: 1px;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
	.copyright {
		padding-right: 20px;
	}
	.social-links {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.title {
			margin-right: 10px;
			line-height: 1.2;
		}
	}
	.social-icons {
		flex-shrink: 0;
		ul {
			margin: 0;
			display: flex;
			align-items: flex-start;
		}
		li {
			margin: 0 0 0 10px;
			&:first-child {
				margin-left: 0;
			}
		}
		a {
			display: inline-block;
			&:hover, &:focus {
				img {
					transform: scale(1.1);
				}
			}
		}
		img {
			width: 22px;
			height: auto;
			margin: 0;
			transition: transform .1s;
		}
	}
}

@include breakpoint(700px) {
	.page-sub-footer {
		.inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.copyright {
			margin-bottom: 0;
		}
	}
}