/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form button[type="submit"] {
	background-color: $brand;
	font-family: $main-font;
	font-weight: $alt-bold;
	color: $white;
	line-height: 1.2;
	border: solid 2px $white;
	border-radius: 22px;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
    text-decoration: none;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    padding: 9px 23px;
    margin: 0 0 1.25rem;
	transition: background-color 0.3s ease 0s;
	display: inline-block;
	&::after {
		display: none;
	}
	&:hover,
	&:focus {
		background: $brand-alt;
		color: $white;
		cursor: pointer;
	}
	&[disabled] {
		background-color: #AAA;
		cursor: not-allowed;
		&:hover,
		&:focus {
			background-color: #AAA;
			cursor: not-allowed;
		}
	}
}

form button[type="submit"] {
	background-color: $brand;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
}

.button.small {
	font-size: rem-calc(16);
	padding: 9px 16px;
	line-height: 1;
}

.button.large {
	font-size: rem-calc(20);
	padding: 12px 28px;
	line-height: 1;
}

.button.brand {
	background-color: $brand;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
}
.button.pre-icon {
	.icon {
		margin-right: 4px;
	}
}
.button.brand-alt {
	background-color: $brand-alt;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
}

.button.brand-accent {
	background-color: $brand-accent;
	&:hover,
	&:focus {
		background: $brand-alt;
	}
}

.button-group {
	text-align: center;
	margin: 0 auto 40px;
	.button {
		margin: 10px;
	}
}

.button-group-vertical {
	margin-bottom: 40px;
	.button {
		margin-bottom: 10px;
	}
}