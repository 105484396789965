.content-action-nav {
	font-size: rem-calc(16);
	font-family: $main-font;
	font-weight: $main-normal;
	margin: 0 auto 30px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
	gap: 10px 20px;
	img {
		height: 14px;
		margin: 0;
		border: 0;
		padding: 0;
		box-shadow: none;
	}
	.item {
		min-height: 42px;
		width: 100%;
		background: $white;
		font-weight: $main-bold;
		line-height: 1.1;
		padding: 11px 20px;;
		border: 1px solid #F2E9E1;
		border-radius: 2px;
		display: flex;
		align-items: center;
	}
	.icon {
		width: 22px;
		margin-right: 8px;
		display: flex;
		align-items: center;
		text-align: center;
		img {
			margin-left: auto;
		}
	}
	a {
		text-decoration: none;
	}
}

@include breakpoint(medium) {
	.content-action-nav {
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 10px;
		.item {
			width: auto;
		}
		.icon {
			width: auto;
		}
	}
}